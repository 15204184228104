@import url("https://fonts.googleapis.com/css?family=Allerta+Stencil&display=swap");

$mobile: "only screen and (max-width : 1023px)";
$desktop: "only screen and (min-width : 1024px)";
$large-desktop: "only screen and (min-width : 1280px)";
$xlarge-desktop: "only screen and (min-width : 1920px)";

// Colors
$color-bg-light: #fff;
$color-bg-input: #333333;
$color-bg-input-focus: #3c3c3c;
$color-text-input-placeholder: #777777;
$color-bg-semidark: #1f1f1f;
$color-bg-dark: #0d0d0d;
$color-bg-darker: #171717;
$color-bg-video: #040404;
$color-bg-darkest: #000000;
$color-bg-tan: #977955;
$color-bg-light-grey: #f5f5f5;
$color-bg-cart: #eeeeee;
$color-text: #ffffff;
$color-text-lighter: #666666;
$color-text-lighter-dark: #999999;
$color-interaction: #bcf135;
$color-interaction-hover: #a5e402;
$color-support-accented: #c7c6c6;
$color-error: #d8131d;
$color-error-text: #ff9999;
$color-bg-input-error: #6a2828;
$color-error-background: #fef6f6;
$color-green: #000;

$font: "Allerta Stencil", sans-serif;

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: $font;
  font-weight: 400;
  color: $color-bg-light;
  width: 100%;
  height: 100%;
  position: relative;
}

.donation-section {
  text-align: left;
  width: 100%;
  border-bottom: 1px solid #333333;
  padding-bottom: 24px;
  opacity: 1;
  pointer-events: all;
  &.inactive {
    opacity: 0.5;
    pointer-events: none;
  }
  &.top-border {
    border-top: 1px solid #333333;
    padding-top: 24px;
    margin-top: 12px;
  }
}

.donation-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 24px 16px;
  width: 90%;
  @media #{$mobile} {
    width: 100%;
  }
}

.donation-explainer {
  font-size: 14px;
  color: $color-text-input-placeholder;
  text-align: center;
}

.donation-section-header {
  text-align: center;
}

.donation-button {
  padding: 8px 12px;
  border-radius: 40px;
  text-transform: none;
  min-width: 41px;
  @media #{$mobile} {
    font-size: 14px;
    padding: 6px 6px;
    border-radius: 40px;
    min-width: 33px;
    margin: 8px 0;
  }
  &.unselected {
    border: 2px solid $color-interaction;
    color: $color-interaction;
    background-color: transparent;
    &:hover {
      color: $color-bg-darkest;
      background-color: $color-interaction;
    }
  }
  &.selected {
    border: 2px solid $color-interaction;
    color: $color-bg-darkest;
    background-color: $color-interaction;
  }
}
