$mobile: "only screen and (max-width : 620px)";
$desktop: "only screen and (min-width : 1024px)";

// Colors
$color-bg-light: #fff;
$color-bg-input: #333333;
$color-bg-input-focus: #3c3c3c;
$color-text-input-placeholder: #777777;
$color-bg-semidark: #1f1f1f;
$color-bg-dark: #0d0d0d;
$color-bg-darker: #171717;
$color-bg-video: #040404;
$color-bg-darkest: #000000;
$color-bg-tan: #977955;
$color-bg-light-grey: #f5f5f5;
$color-bg-cart: #eeeeee;
$color-text: #ffffff;
$color-text-lighter: #666666;
$color-text-lighter-dark: #999999;
$color-interaction: #bcf135;
$color-interaction-hover: #a5e402;
$color-support-accented: #c7c6c6;
$color-error: #d8131d;
$color-error-text: #ff9999;
$color-bg-input-error: #6a2828;
$color-error-background: #fef6f6;
$color-green: #000;
// Font
$font: "Allerta Stencil", sans-serif;

.modal {
  background-color: rgba(0, 0, 0, 0.6);
  color: $color-text;
  width: 100vw;
  height: 100%;
  z-index: 10;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-frame {
    background-color: $color-bg-dark;
    min-width: 600px;
    min-height: 400px;
    max-height: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 30px;
    border-radius: 16px;
    &.info {
      justify-content: center;
      @media #{$mobile} {
        align-items: flex-start;
      }
      p {
        max-width: 400px;
      }
    }
    @media #{$mobile} {
      width: 90%;
      min-width: auto;
      min-height: 300px;
    }
    &-header {
      text-transform: uppercase;
      margin-top: 0;
    }
    &-close-button {
      background-color: transparent;
      padding: 10px;
      position: absolute;
      top: 10px;
      right: 0;
    }
    &-size-buttons {
      display: flex;
      .size-button {
        font-size: 14px;
        padding: 6px;
        margin-right: 10px;
        min-width: 33px;
        border: 2px solid transparent;
        &.unselected {
          color: $color-text;
          background-color: $color-bg-darker;
        }
        &:last-of-type {
          margin-right: 0;
        }
        &:disabled {
          text-decoration: line-through;
          color: $color-text;
          background-color: $color-bg-darker;
        }
      }
    }
    .proceed-button {
      margin-top: 10px;
      &.visible {
        visibility: visible;
      }
      &.hidden {
        visibility: hidden;
      }
    }
    .qualification-disclaimer-label {
      display: block;
      position: relative;
      padding-left: 35px;
      margin: 12px 0 20px;
      cursor: pointer;
      font-size: 16px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      line-height: 25px;
      input:checked ~ .checkmark:after {
        display: block;
      }
      input:checked ~ .checkmark {
        background-color: $color-bg-input;
      }
      &:hover input ~ .checkmark {
        background-color: $color-bg-input-focus;
        transition: background-color 150ms ease;
      }
      .checkmark:after {
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid $color-text;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    .modal-checkbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      border: 2px solid transparent;
      background-color: $color-bg-input;
      box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
        rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
      border-radius: 4px;
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
    .size-graphic {
      position: relative;
      font-size: 14px;
      margin-top: 32px;
      @media #{$mobile} {
        font-size: 12px;
      }
      img {
        max-width: 100%;
        @media #{$desktop} {
          max-height: 360px;
        }
      }
      .chest-circumference {
        &.tshirt {
          position: absolute;
          transform: rotate(0deg);
          top: 22%;
          left: 49%;
          color: $color-interaction;
          @media #{$mobile} {
            top: 19%;
            left: 47%;
          }
        }
        &.cap {
          position: absolute;
          transform: rotate(17deg);
          top: 42%;
          left: 56%;
          color: $color-interaction;
          @media #{$mobile} {
            top: 40%;
            left: 55%;
          }
        }
        &.hoodie {
          position: absolute;
          transform: rotate(0deg);
          top: 30%;
          left: 49%;
          color: $color-interaction;
          @media #{$mobile} {
            top: 26%;
            left: 47%;
          }
        }
      }
      .arm-length {
        &.tshirt {
          position: absolute;
          transform: rotate(-27deg);
          top: 1%;
          left: 4%;
          color: $color-support-accented;
          @media #{$mobile} {
            top: -2%;
            left: 1%;
          }
        }
        &.hoodie {
          position: absolute;
          transform: rotate(-55deg);
          top: 38%;
          left: 10%;
          color: $color-support-accented;
          @media #{$mobile} {
            top: 30%;
            left: 5%;
          }
        }
      }
      .height {
        position: absolute;
        top: 60%;
        left: 41%;
        @media #{$mobile} {
          top: 60%;
          left: 41%;
        }
      }
      .hand-length {
        position: absolute;
        top: 40%;
        left: 2%;
        color: $color-support-accented;
        @media #{$mobile} {
          top: 40%;
          left: -2%;
        }
      }

      .hand-length.glove-2 {
        color: white;
        position: absolute;
        top: 0%;
        left: 42%;
        @media #{$mobile} {
          top: 4%;
          left: 53%;
        }
      }
      .glove-length {
        position: absolute;
        top: 50%;
        left: 81%;
        @media #{$mobile} {
          top: 50%;
          left: 82%;
        }
      }
      .circumference {
        position: absolute;
        transform: rotate(9deg);
        top: 62%;
        left: 45%;
        color: $color-interaction;
        @media #{$mobile} {
          top: 63%;
          left: 43%;
        }
        &.shorts {
          top: 2%;
          left: 46%;
          transform: rotate(0deg);
        }
      }
      .circumference.glove-2 {
        position: absolute;
        transform: rotate(0deg);
        top: 53.5%;
        left: 67%;
        color: $color-interaction;
        @media #{$mobile} {
          top: 53%;
          left: 68%;
        }
      }
      .length {
        position: absolute;
        &.shorts {
          top: 48%;
          left: 4%;
          transform: rotate(-67deg);
        }
      }
    }
    .size-up-disclaimer {
      margin-top: 24px;
      font-size: 14px;
      color: $color-bg-light-grey;
    }
    .modal-button-row {
      margin-top: 32px;
      @media #{$desktop} {
        display: none;
      }
      button {
        padding: 12px 20px;
      }
    }
  }
}
