@import url("https://fonts.googleapis.com/css?family=Allerta+Stencil&display=swap");

$mobile: "only screen and (max-width : 1023px)";
$desktop: "only screen and (min-width : 1024px)";
$large-desktop: "only screen and (min-width : 1280px)";
$xlarge-desktop: "only screen and (min-width : 1920px)";

.root-container.category-view {
  background: url("/icons/watches-banner.jpg") no-repeat left top fixed;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  overflow-y: scroll;
  @media #{$mobile} {
    height: auto;
    min-height: 100%;
  }

  h2 {
    z-index: 2;
  }
}

.spinner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}
