@import url("https://fonts.googleapis.com/css?family=Allerta+Stencil&display=swap");

$mobile: "only screen and (max-width : 1023px)";
$desktop: "only screen and (min-width : 1024px)";
$large-desktop: "only screen and (min-width : 1280px)";
$xlarge-desktop: "only screen and (min-width : 1920px)";

// Colors
$color-bg-light: #fff;
$color-bg-input: #333333;
$color-bg-input-focus: #3c3c3c;
$color-text-input-placeholder: #777777;
$color-bg-semidark: #1f1f1f;
$color-bg-dark: #0d0d0d;
$color-bg-darker: #171717;
$color-bg-video: #040404;
$color-bg-darkest: #000000;
$color-bg-tan: #977955;
$color-bg-light-grey: #f5f5f5;
$color-bg-cart: #eeeeee;
$color-text: #ffffff;
$color-text-lighter: #666666;
$color-text-lighter-dark: #999999;
$color-interaction: #bcf135;
$color-interaction-hover: #a5e402;
$color-support-accented: #c7c6c6;
$color-error: #d8131d;
$color-error-text: #ff9999;
$color-bg-input-error: #6a2828;
$color-error-background: #fef6f6;
$color-green: #000;

$font: "Allerta Stencil", sans-serif;

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: $font;
  font-weight: 400;
  color: $color-bg-light;
  width: 100%;
  height: 100%;
  position: relative;
}

body {
  background: transparent;
}

figure {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.root-container.nvg-mount {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  overflow-y: scroll;
  @media #{$mobile} {
    height: auto;
    min-height: 100%;
  }
}

.section-nvg-mount {
  font-size: 18px;
  padding-bottom: 50px;
  .text {
    margin: 24px;
    max-width: 1000px;
  }
  img {
    width: 100%;
    max-width: 1000px;
  }
  p {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .bullet-number {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 28px;
    height: 28px;
    border-radius: 20px;
    background-color: $color-bg-darker;
    color: $color-text-lighter;
    margin-right: 10px;
    font-size: 18px;
  }
}

.site-header.transparent {
  background-color: transparent;
  backdrop-filter: blur(8px);
}

.custom-banner-image {
  width: 100%;
}

textarea {
  resize: none;
}
