@import url("https://fonts.googleapis.com/css?family=Allerta+Stencil&display=swap");

$mobile: "only screen and (max-width : 1023px)";
$desktop: "only screen and (min-width : 1024px)";
$large-desktop: "only screen and (min-width : 1280px)";
$xlarge-desktop: "only screen and (min-width : 1920px)";

// Colors
$color-bg-light: #fff;
$color-bg-input: #333333;
$color-bg-input-focus: #3c3c3c;
$color-text-input-placeholder: #777777;
$color-bg-semidark: #1f1f1f;
$color-bg-dark: #0d0d0d;
$color-bg-darker: #171717;
$color-bg-video: #040404;
$color-bg-darkest: #000000;
$color-bg-tan: #977955;
$color-bg-light-grey: #f5f5f5;
$color-bg-cart: #eeeeee;
$color-text: #ffffff;
$color-text-lighter: #666666;
$color-text-lighter-dark: #999999;
$color-interaction: #bcf135;
$color-interaction-hover: #a5e402;
$color-support-accented: #c7c6c6;
$color-error: #d8131d;
$color-error-text: #ff9999;
$color-bg-input-error: #6a2828;
$color-error-background: #fef6f6;
$color-green: #000;

$font: "Allerta Stencil", sans-serif;

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: $font;
  font-weight: 400;
  color: $color-bg-light;
  width: 100%;
  height: 100%;
  position: relative;
}

body {
  background: transparent;
}

figure {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.root-container.custom-patches {
  width: 100vw;
  height: 100vh;
  background: url("/icons/camp-mordor.jpg") no-repeat center center fixed;
  background-size: cover;
  overflow-y: scroll;
  @media #{$mobile} {
    height: auto;
    min-height: 100%;
  }
}

.site-header.transparent {
  background-color: transparent;
  backdrop-filter: blur(8px);
}

.custom-banner-image {
  width: 100%;
}

textarea {
  resize: none;
}

.custom-request-form-intro-text {
  width: 100%;
  padding: 20px;
  background-color: $color-bg-darker;
}

.form-content {
  width: 100%;
}

.section-container.custom-request {
  max-width: 700px;
  padding: 40px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  box-shadow: 1px 1px 69px -19px rgba(0, 0, 0, 0.7);
  margin: 40px auto;
  @media #{$mobile} {
    padding: 20px;
    backdrop-filter: none;
    background-color: rgba(0, 0, 0, 0.9);
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 0;
    margin-top: 0;
    box-shadow: none;
  }
}

.section-custom-request {
  @media #{$mobile} {
    align-items: flex-start;
  }
}

.custom-request-header {
  text-align: center;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.form-label-big {
  margin-top: 20px;
  &.product {
    margin-top: 20px;
  }
}

.custom-request-form {
  width: 100%;
  .custom-request-send-button {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    &.submitting {
      padding: 0;
    }
  }
  input {
    &.validation-error {
      color: $color-error-text;
      transition: color 200ms ease-in-out;
    }
    &:focus {
      box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
        rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
      transition: all 150ms ease;
    }
  }
}

.custom-request-selectors {
  display: flex;
  @media #{$mobile} {
    flex-direction: column;
  }
  .pay-method-btn {
    border: none;
    padding-left: 26px;
    margin-left: 20px;
    @media #{$mobile} {
      margin-left: 0;
    }
    &:first-of-type {
      margin-left: 0;
    }
    &::before {
      left: 0;
    }
    &::after {
      left: 4px;
    }
  }
}

.request-form-confirmation {
  text-align: center;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  a {
    margin-top: 20px;
  }
  @media #{$mobile} {
    height: calc(100vh - 140px);
  }
}
