$mobile: "only screen and (max-width : 620px)";

.menu-links-container {
  button {
    background-color: transparent;
    border: none;
    padding: 0;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: transparent;
    }
  }
  .anchor-link {
    transition: opacity 300ms;
    &.loading {
      pointer-events: none;
      opacity: 0.2;
    }
  }
  .anchor-link.search-field-button {
    @media #{$mobile} {
      display: none;
    }
    img {
      pointer-events: none;
    }
  }
  .search-field {
    max-width: 364px;
    margin: 0;
    height: 51px;
    border-radius: 0;
  }
}
