@import url("https://fonts.googleapis.com/css?family=Allerta+Stencil&display=swap");

$mobile: "only screen and (max-width : 1023px)";
$desktop: "only screen and (min-width : 1024px)";
$large-desktop: "only screen and (min-width : 1280px)";
$xlarge-desktop: "only screen and (min-width : 1920px)";

// Colors
$color-bg-light: #fff;
$color-bg-input: #333333;
$color-bg-input-focus: #3c3c3c;
$color-text-input-placeholder: #777777;
$color-bg-semidark: #1f1f1f;
$color-bg-dark: #0d0d0d;
$color-bg-darker: #171717;
$color-bg-video: #040404;
$color-bg-darkest: #000000;
$color-bg-tan: #977955;
$color-bg-light-grey: #f5f5f5;
$color-bg-cart: #eeeeee;
$color-text: #ffffff;
$color-text-lighter: #666666;
$color-text-lighter-dark: #999999;
$color-interaction: #bcf135;
$color-interaction-hover: #a5e402;
$color-support-accented: #c7c6c6;
$color-error: #d8131d;
$color-error-text: #ff9999;
$color-bg-input-error: #6a2828;
$color-error-background: #fef6f6;
$color-green: #000;

$font: "Allerta Stencil", sans-serif;

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: $font;
  font-weight: 400;
  color: $color-bg-light;
  width: 100%;
  height: 100%;
  position: relative;
}

.root-container.product-landing-page,
.root-container.spinner-container {
  background: $color-bg-darkest;
  &.cart-visible {
    padding-bottom: 120px;
  }
  padding-bottom: 60px;
  section {
    background: $color-bg-darkest;
  }
}

.root-container.product-landing-page {
  .type-dropdown {
    margin-top: 30px;
    justify-content: flex-start;
    width: auto;
    max-width: 300px;
    .size-label {
      @media #{$mobile}  {
        font-size: 14px;
      }
    }
    select {
      width: 100%;
      @media #{$mobile} {
        font-size: 16px;
        height: 41px;
      }
    }
    @media #{$mobile} {
      width: 100%;
      max-width: none;
    }
    .select-dropdown-arrow {
      @media #{$mobile} {
        right: 8px;
      }
    }
  }
}

.page-not-found-container {
  width: 100vw;
  height: 100vh;
  background: url("/icons/banner-17.jpg") no-repeat center center fixed;
  background-size: cover;
  overflow-y: scroll;
}

#page-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 120px);
  @media #{$mobile} {
    height: calc(100% - 100px);
  }
  .text-container {
    padding: 20px;
    margin: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    height: auto;
    text-align: center;
    border-radius: 10px;
    font-size: 20px;
  }
}

.spinner-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-product-view {
  width: 100%;
  height: calc(100% - 120px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background: $color-bg-darkest;
  @media #{$mobile} {
    height: calc(100% - 100px);
    flex-direction: column;
  }
}

.image-container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  .product-image {
    height: 100%;
    img {
      height: 100%;
    }
  }
  @media #{$mobile} {
    width: 100%;
  }
  .size-guide-button {
    bottom: 10px;
    right: 10px;
    top: auto;
    padding: 7px 12px;
    font-size: 14px;
    font-size: 13px;
    img {
      margin-left: 6px;
      @media #{$mobile} {
        margin: 0;
      }
    }
  }
}

.product-description {
  max-width: 500px;
}

.product-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &.gallery {
    cursor: pointer;
  }
  img {
    width: 100%;
    pointer-events: none;
  }
  img.product-more-images-icon {
    position: absolute;
    z-index: 1;
    left: 10px;
    bottom: 10px;
    height: 20px;
    width: 25px;
    opacity: 0.2;
    pointer-events: none;
  }
}

.text-container {
  height: 100%;
  width: 50%;
  padding: 50px;
  position: relative;
  @media #{$mobile} {
    width: 100%;
    padding: 20px;
  }

  .product-price {
    margin: 20px 0;
    font-size: 22px;
  }

  .size-buttons {
    justify-content: flex-start;
    margin: 30px 0 10px 0;
  }

  .product-cart-button {
    button.product-buy-button {
      font-size: 16px;
      padding: 16px 24px;
    }
  }

  .notify-in-stock {
    margin-top: 20px;
    z-index: 1;
    border-radius: 20px;
    padding: 6px 10px;
    font-size: 15px;
    color: $color-interaction;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    &.disabled {
      pointer-events: none;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
    .notification-icon {
      margin-right: 5px;
      width: 14px;
      height: 14px;
      &.animation {
        animation: bell 4000ms ease-in-out 1;
      }
    }
  }
  .notify-instagram-link {
    border-radius: 4px;
    padding: 6px;
    font-size: 12px;
    color: $color-interaction;
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    cursor: pointer;
    animation: instagram-link-appear 1400ms ease-out 1;
    &-icon {
      height: 15px;
      margin-right: 5px;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
  input {
    &.validation-error {
      color: $color-error-text;
      transition: color 200ms ease-in-out;
    }
    &:focus {
      box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
        rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
      transition: all 150ms ease;
    }
  }
  .notify-me-send-button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .notify-me-error {
    color: $color-error-text;
    margin: 20px 0;
  }
  .notify-me-form {
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 40px;
    color: $color-bg-light-grey;
    z-index: 2;
    max-width: 600px;
    @media #{$mobile} {
      padding: 20px;
      max-width: none;
      width: 100%;
    }
    .notify-me-form-header {
      margin-top: 48px;
    }
    .close-notify-form-button {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 48px;
      height: 48px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      cursor: pointer;
      &:hover {
        background-color: $color-bg-darker;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.campaign-label {
  position: absolute;
  z-index: 1;
  top: 22px;
  right: -32px;
  height: 32px;
  padding-bottom: 3px;
  background-color: #bcf135;
  color: #0d0d0d;
  transform: rotate(45deg);
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-data-link-label {
  font-size: 14px;
}

a.product-data-link {
  font-size: 14px;
}

.product-buy-button {
  padding: 20px 30px;
  font-size: 18px;
  &:disabled {
    pointer-events: none;
  }
  @media #{$mobile} {
    font-size: 13px;
    padding: 10px 12px;
  }
}

.all-products-button {
  display: none;
  @media #{$mobile} {
    &.cart-visible {
      bottom: 60px;
    }
    bottom: 0;
    left: 0;
    position: fixed;
    display: flex;
    font-size: 14px;
    color: $color-text-lighter-dark;
    width: 100%;
    background-color: $color-bg-dark;
    margin: 40px 0 0 0;
  }
}

.all-products-link {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border: none;
}

.product-name {
  font-size: 22px;
  margin-bottom: 10px;
}

.product-images-list {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  @media #{$mobile} {
    margin-top: 0;
  }
  .product-images-list-item {
    width: 100%;
    margin-bottom: 2px;
  }
}
